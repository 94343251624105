import { Injectable } from '@angular/core';
import { ConfiguratorApiService } from './configurator-api.service';
import { AdjustableArea, AreaData, ProdConfig } from '../../interfaces/configurator';
import { Observable, Subject } from 'rxjs';
import * as _ from 'lodash';
import { GlobalService } from '../global/global.service';
import { ProdConfigMaterial } from '../../interfaces/configurator/product-config/prod-config-material.interface';

@Injectable({
  providedIn: 'root'
})
export class ConfiguratorService {
  public Configurator;
  public iframeCallback = new Subject<string>();
  public applyConfigurator = new Subject<any>();
  public applyConfiguratorAreas = new Subject<any>();
  public orderImage;
  public orderSaveData;
  public allowInit = false;
  public initNewTable = false;
  public sceneLoaded = false;
  public configurationChanged = false;
  public productInitialization = true;
  public productAbelToUnit: boolean = null;
  public screenshot;
  public productAsset;
  public inputProdConfig;
  public dataObject: { groupId: number; data: any; order: any };
  private savedProduct = [];
  public store;
  private data;
  private id;
  private mainId;
  private areaId;

  constructor(private configuratorApiService: ConfiguratorApiService, private globalService: GlobalService) {
    this.Configurator = (data, savedStore) => {
      this.areaId = data.adjustable_areas[0].area_id;
      if (!savedStore) {
        this.store = {
          areasParamsOrigin: {},
          areasParams: {},
          prodPrice: {},
          configuredAreas: {},
          configuredOptionalAreas: {}
        };
        this.data = this.init(data);
        this.savedProduct[data.pk] = {
          store: this.store,
          data: this.data
        };
      } else {
        this.store = savedStore;
        this.data = data;
      }
      if (data.pk) {
        this.id = data.pk;
        this.mainId = data.pk;
      } else if (data.product_id) {
        this.id = data.product_id;
        this.mainId = data.product_id;
      }
    };
  }

  init(data) {
    _.forEach(data.adjustable_areas, (areaData) => {
      // cache initial areas params
      this.store.configuredAreas[areaData.area_id] = {
        area_price_id: areaData.area_price_id,
        materials: areaData.materials
      };
      this.store.areasParams[areaData.area_id] = { selected_order: [] };

      data.product_price = this.evalPrice(areaData);
    });
    _.forEach(data.optionals, (optional) => {
      this.setAdditionalArea(optional);
      if (optional.is_active) {
        data.product_price = this.evalPrice(optional);
      }
    });
    return data;
  }

  getNewConfigurator(productId): Observable<ProdConfig> {
    this.savedProduct = [];
    return this.configuratorApiService.get(productId);
  }

  getNavbarData(areaId, areaParams, unitParam, configuredParams?): Observable<AreaData> {
    this.setAreaParams(areaId, areaParams, null);
    return this.configuratorApiService.edit(this.id, configuredParams ? configuredParams : this.getAreasParams(areaId, unitParam));
  }

  getAreasParams(areaId, unitParam) {
    const data = this.productAbelToUnit
      ? {
          area_id: areaId,
          unit_tables: this.productAbelToUnit
        }
      : { area_id: areaId };
    if (this.store.areasParams[areaId].selected_order.length) {
      if (this.productAbelToUnit && unitParam != null) {
        if (!unitParam) {
          const indexOfExtension = this.store.areasParams[areaId].selected_order.find((el) => el === 'mat_area_id_173');
          if (indexOfExtension) {
            this.store.areasParams[areaId].selected_order.pop();
          }
          delete this.store.areasParams[areaId].mat_area_id_173;
        }
      }
      Object.assign(data, this.store.areasParams[areaId]);
    }
    return data;
  }

  setAreaParams(areaId, areaParams, paramsReset) {
    let areaParamKey;
    if (areaParams) {
      areaParamKey = _.keys(areaParams)[0];
      this.store.areasParams[areaId].current = areaParamKey;
      if (paramsReset) {
        this.store.areasParams[areaId].selected_order = [];
      }
      if (this.store.areasParams[areaId].selected_order.indexOf(areaParamKey) === -1) {
        this.store.areasParams[areaId].selected_order.push(areaParamKey);
      }
      Object.assign(this.store.areasParams[areaId], areaParams);
    }
  }

  updateConfiguratorProperties(areaData) {
    if (!this.productAbelToUnit && areaData.product_pk) {
      this.id = areaData.product_pk;
    }
    areaData.product_price = this.evalPrice(areaData);
    this.setConfiguredArea(areaData);
    this.saveConfiguredProduct();
  }

  saveConfiguredProduct() {
    this.savedProduct[this.id] = {
      store: this.store,
      data: this.data
    };
    sessionStorage.setItem(this.id, JSON.stringify(this.savedProduct[this.id]));
  }

  setConfiguredArea(areaData) {
    const materials = _.reduce(
      areaData.materials,
      (result, material) => {
        _.each(material.material_groups, (e) => {
          const defaultMat = _.find(e.mat_images, { default: true });

          if (!defaultMat) {
            return result;
          }

          result.push({
            mat_area_name: material.mat_area_name,
            mat_image_id: defaultMat.id,
            mat_name: e.mat_name,
            material_id: e.material_id
          });
        });

        return result;
      },
      []
    );
    this.store.configuredAreas[areaData.area_id] = {
      area_price_id: areaData.area_price_id,
      materials
    };
  }

  setAdditionalArea(areaData) {
    if (this.data) {
      const arr = [];
      this.data.optionals = _.forEach(this.data.optionals, (optional) => {
        if (areaData.area_id === optional.area_id) {
          optional.is_active = areaData.is_active;
        }
        arr.push(optional);
        return arr;
      });

      this.saveConfiguredProduct();
    }
    if (areaData.areas != null) {
      this.store.configuredOptionalAreas[areaData.areas.area_id] = {
        area_price_id: areaData.areas.area_price_id,
        is_active: areaData.areas.is_active
      };

      if (areaData.is_active) {
        this.store.prodPrice[areaData.area_id] = {
          id: areaData.area_price_id,
          value: areaData.area_price
        };
      } else {
        delete this.store.prodPrice[areaData.area_id];
      }
    }
  }

  evalPrice(areaData) {
    if (areaData != null) {
      if (areaData.area_id != null) {
        const areaId = areaData.area_id;
        const areaPrice = areaData.area_price;
        const areaPriceId = areaData.area_price_id;

        this.store.prodPrice[areaId] = {
          id: areaPriceId,
          value: areaPrice
        };
      }
    }
    return _.reduce(
      this.store.prodPrice,
      (sum, el) => {
        return el.value === '-' ? el.value : sum + el.value;
      },
      0
    );
  }

  buildDataForSave(data) {
    const productsData = [];
    this.savedProduct.forEach((prod) => {
      productsData.push({
        id: this.id,
        adjustable_areas: _.values(prod.store.configuredAreas),
        optionals: _.values(prod.store.configuredOptionalAreas),
        image: data.screenshot
      });
    });
    return productsData;
  }

  getQRCodeData(productPk, productData): Observable<{ token: string }> {
    return this.configuratorApiService.getQRCodeToken(productPk, productData, this.globalService.typeOfOrigin === 'gfg');
  }

  formatDataForUnity(areaData) {
    const data = [];
    if (typeof areaData === 'object') {
      areaData.forEach((subarea) => {
        subarea.material_groups.forEach((group) => {
          group.mat_images.forEach((matImg) => {
            if (matImg.default && matImg.image_asset) {
              data.push({ mesh: subarea.mat_area_name, material: matImg.image_asset });
            }
          });
        });
      });
    }
    return data;
  }

  createApplyConfiguration(): {} {
    const adjustableAreas: AdjustableArea = this.inputProdConfig.adjustable_areas[0];
    const data = {
      adjustable_areas: [
        {
          area_price_id: adjustableAreas.area_price_id,
          materials: []
        }
      ],
      optionals: [],
      image: ''
    };
    adjustableAreas.materials.forEach((material: ProdConfigMaterial) => {
      Object.entries(this.dataObject.order).forEach((entries: [any, any]) => {
        const [key, value] = entries;
        if (key === 'mat_area_id_' + material.mat_area_id) {
          const colorId = value.mat_image_color_id ? value.mat_image_color_id : material.mat_image_palette_color;
          const paletteId = value.mat_image_palette_id ? value.mat_image_palette_id : material.mat_image_palette;
          data.adjustable_areas[0].materials.push({
            mat_area_name: material.mat_area_name,
            mat_image_id: value.mat_image_id,
            mat_name: material.mat_area_visible_name,
            material_id: value.material_id,
            material_image_palette_id: paletteId,
            material_image_palette_color_id: colorId
          });
        }
      });
    });
    return data;
  }
}
