import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../../api/api';
import { Observable } from 'rxjs';
import { AreaData, ProdConfig } from '../../interfaces/configurator';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfiguratorApiService {
  private url = this.api.URL;
  private apiUrl = this.url + environment.PRODUCT_CONFIGURATOR_PLUGIN;

  constructor(private http: HttpClient, private api: ApiService) {}

  get(id): Observable<ProdConfig> {
    if (this.api.typeOfOrigin === 'gfg') {
      this.url = this.api.URL;
      this.apiUrl = this.url + environment.PRODUCT_CONFIGURATOR_GFG;
    }
    // return this.http.delete<ProdConfig>('https://dev-gfg-api-new.vakoms.com/app/product_details/1543/product_configurator/');

    return this.http.get<ProdConfig>(this.apiUrl.replace('__prodId__', id));
  }

  edit(id, data): Observable<AreaData> {
    data = JSON.stringify(data);
    return this.http.post<AreaData>(this.apiUrl.replace('__prodId__', id), data);
  }

  placeOrder(id, client) {
    return this.http.post(this.url + `/iframe_api/products/${id}/product_configurator/place_order/`, client);
  }

  gfgOrder(id, data) {
    return this.http.put(this.url + `/app/product_details/${id}/product_configurator/`, data);
  }

  gfgQTY(id, data) {
    return this.http.post(this.url + `/app/product_details/${id}/quantity/`, data);
  }

  setMaterialRequest(id, data, gfg) {
    if (gfg) {
      return this.http.post(this.url + `/app/product_details/${id}/product_configurator/`, data);
    } else {
      return this.http.post(this.url + `/iframe_api/products/${id}/product_configurator/`, data);
    }
  }

  setColorRequest(id, data, gfg) {
    if (gfg) {
      return this.http.post(this.url + `/app/product_details/${id}/product_configurator/color-price/`, data);
    } else {
      return this.http.post(this.url + `/iframe_api/products/${id}/product_configurator/color-price/`, data);
    }
  }

  request3DModel(pk, body, gfg) {
    if (gfg) {
      return this.http.post(this.url + `/app/product_details/${pk}/3dmodel/order/`, body);
    } else {
      return this.http.post(this.url + `/iframe_api/products/${pk}/3dmodel/order/`, body);
    }
  }

  sendProductImg(id, data) {
    return this.http.put(this.url + `/iframe_api/orders/${id}/update_image/`, data);
  }

  getQRCodeToken(id, data, gfg): Observable<{ token: string }> {
    if (gfg) {
      return this.http.post<{ token: string }>(this.url + `/app/product_details/${id}/product_configurator/qr/`, data);
    } else {
      return this.http.post<{ token: string }>(this.url + `/iframe_api/products/${id}/product_configurator/ar_app_token/`, data);
    }
  }

  getQRCodeTokenGFG(id): Observable<{ token: string }> {
    return this.http.get<{ token: string }>(this.url + `/app/product_details/${id}/qr/`);
  }

  getBaseUrl(): string {
    return this.url;
  }
}
