import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route } from '@angular/router';
import { GlobalService } from '../services/global/global.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ProductGuard implements CanActivate {
  constructor(private _router: Router, private globalService: GlobalService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.globalService.typeOfPlugin === 'full') {
      return true;
    }

    // navigate to login page
    return false;
  }
}
