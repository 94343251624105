import { Component, HostListener } from '@angular/core';
import { ProductsService } from './core/services/products/products.service';
import { Router } from '@angular/router';
import { ConfiguratorApiService } from './core/services/configurator/configurator-api.service';
import { ConfiguratorService } from './core/services/configurator/configurator.service';
import { AuthService } from './core/services/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from './core/services/global/global.service';
import { DomSanitizer } from '@angular/platform-browser';
import swal from 'sweetalert';
import { MatIconService } from './core/services/mat-icon/mat-icon.service';
import { CollectionProduct } from './core/interfaces/products/collection-product.interface';
import { environment } from '../environments/environment';
import { ApiService } from './core/api/api';

interface TokenResp {
  token: string;
  language: string;
  site_colors: { primary_color: string; secondary_color: string; text_color: string };
  font_family?: string;
}

interface ProductCode {
  product_id: number;
  sub_collection: { default_product_id: number; pk: number };
}

declare global {
  interface Window {
    Number: any;
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  private cookieMessageShow = true;

  constructor(
    private router: Router,
    private authService: AuthService,
    private globalService: GlobalService,
    private productsService: ProductsService,
    private configuratorService: ConfiguratorService,
    private configuratorApiService: ConfiguratorApiService,
    private matIconService: MatIconService,
    private translate: TranslateService,
    private sanitizer: DomSanitizer,
    private api: ApiService
  ) {
    this.sanitizer = sanitizer;
    const tutorialShowed = localStorage.getItem(`tutorial-showed`) != null;

    // sessionStorage clearing with init
    localStorage.clear();
    sessionStorage.clear();
    if (tutorialShowed) {
      localStorage.setItem(`tutorial-showed`, 'true');
    }
    // mat-icon service initialization
    this.matIconService.registerIcons();

    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('en');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translate.use('en');

    window.top.postMessage({ type: 'init' }, '*');
    this.globalService.landscape = window.outerWidth > window.outerHeight;
  }

  @HostListener('window:message', ['$event'])
  onWindowMessage(event) {
    if (event.data.status === '404') {
      window.top.postMessage({ type: 'close' }, '*');
    } else {
      this.globalService.isMobile = !!this.globalService.detectMobileDevices();
      if (
        event.data.origin === environment.ORIGIN_LIST.LOCALHOST ||
        event.data.origin === environment.ORIGIN_LIST.DEV ||
        event.data.origin === environment.ORIGIN_LIST.STAGE ||
        event.data.origin === environment.ORIGIN_LIST.PROD ||
        event.data.origin === environment.ORIGIN_LIST.PROD_IOS
      ) {
        this.globalService.originUrl = event.data.origin;
        this.api.changeUrl(environment.BACK_END_URL_GFG, 'gfg');
        this.globalService.typeOfOrigin = 'gfg';
        sessionStorage.setItem('token', event.data.token);
        if (event.data.language) {
          this.setConfiguratorLanguage(event.data.language);
        }
        this.globalService.typeOfPlugin = 'configurator';
        if (event.data.quantity) {
          this.globalService.qtyOfProduct = event.data.quantity;
        }
        this.productsService.userData.next({ id: event.data.id });
        this.initSubCollection({ product_id: event.data.id });
      } else if (event.data) {
        this.pluginInitFunctionMethods(event);
      }
    }
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.onCloseFullScreen();
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    this.configuratorService.sceneLoaded = false;
    window.top.postMessage({ type: 'backBtn' }, '*');
  }

  initSubCollection(productCodeResponse) {
    let id;
    if (productCodeResponse.sub_collection == null) {
      id = productCodeResponse.product_id;
      this.productsService.subCollection = null;
    } else {
      id = productCodeResponse.sub_collection.default_product_id;
      this.productsService.subCollections(productCodeResponse.sub_collection).subscribe((subCollection: CollectionProduct) => {
        this.productsService.subCollection = subCollection;
        this.configuratorService.productAbelToUnit = subCollection.collection_products[0].is_able_to_unit;
      });
    }
    this.router.navigate(['/configurator/' + id]);
    window.top.postMessage(
      {
        type: 'display',
        id,
        height: this.globalService.resolutionSettings.height
      },
      '*'
    );
  }

  createOrder(orderData) {
    const data = {
      client: {
        full_name: orderData.client.full_name,
        phone_number: orderData.client.phone_number
      }
    };
    Object.assign(data, this.configuratorService.orderSaveData);
    this.configuratorApiService.placeOrder(orderData.id, data).subscribe((resp: any) => {
      if (resp) {
        this.globalService.openedOrderDialog = false;
        const image = { image: this.configuratorService.orderImage };
        this.configuratorApiService.sendProductImg(resp.order_pk, image).subscribe();
      }
    });
  }

  checkVersion() {
    const agent = window.navigator.userAgent;
    const start = agent.indexOf('OS ');
    if ((agent.indexOf('iPhone') > -1 || agent.indexOf('iPad') > -1) && start > -1) {
      return window.Number(agent.substr(start + 3, 3).replace('_', '.'));
    }
    return 0;
  }

  // iPhoneVersion() {
  //     switch (this.globalService.getiPhoneModel()) {
  //         case 'iPhone 5':
  //             return false;
  //         case 'iPhone 6':
  //             return false;
  //         case 'iPhone 7':
  //             return false;
  //         default:
  //             return true;
  //     }
  // }

  setStyleProperties(stylesData) {
    this.globalService.siteColor = stylesData.site_colors;
    const root = document.documentElement.style;
    // ability to add custom "font family"
    this.sanitizer.bypassSecurityTrustResourceUrl(stylesData.font_family.toString());
    root.setProperty('--customer-font-family', stylesData.font_family);
    root.setProperty('--primary-color', stylesData.site_colors.primary_color);
    root.setProperty('--secondary-color', stylesData.site_colors.secondary_color);
    root.setProperty('--text-color', stylesData.site_colors.text_color);
  }

  setConfiguratorLanguage(language) {
    if (language) {
      switch (language.toString()) {
        case '1':
        case 'Ukrainian':
          this.translate.use('ua');
          break;
        case '2':
        case 'Russian':
          this.translate.use('ru');
          break;
        case '3':
        case 'Italian':
          this.translate.use('it');
          break;
        case '4':
        case 'Polish':
          this.translate.use('pl');
          break;
        default:
          this.translate.use('en');
      }
    }
  }

  onCloseFullScreen() {
    this.productsService.openFull = false;
    window.top.postMessage({ type: 'window' }, '*');
  }

  getBrowserLink() {
    setTimeout(() => {
      if (this.globalService.typeOfOrigin !== 'gfg') {
        swal({
          title: 'Oops...',
          text: 'Cookies are disabled in your browser.\n For correct work unblock it and reload the page.',
          className: 'cookie-alert',
          icon: 'warning',
          buttons: {
            confirm: { text: 'Get help with it' }
          }
        }).then(() => {
          let link;
          if (/opr|opera/i.test(navigator.userAgent)) {
            link = 'https://help.opera.com/en/latest/web-preferences/';
          } else if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
            link = 'https://support.apple.com/uk-ua/guide/safari/sfri11471/mac';
          } else if (/Chrome/.test(navigator.userAgent)) {
            link = 'https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=en';
          } else if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
            link = 'https://support.mozilla.org/en-US/kb/websites-say-cookies-are-blocked-unblock-them';
          } else if (/msie\s|trident\/|edge\//i.test(window.navigator.userAgent)) {
            link = 'https://support.qualityunit.com/022932-How-to-enable-cookies-in-Edge';
          }
          window.open(link);
          this.getBrowserLink();
        });
      }
    }, 1000);
  }

  pluginInitFunctionMethods(event) {
    if ((navigator.cookieEnabled && this.cookieMessageShow) || this.globalService.typeOfOrigin === 'gfg') {
      if (event.data.width && event.data.height) {
        this.globalService.parentWidth = event.data.width;
        this.globalService.parentHeight = event.data.height;
        this.globalService.landscape = this.globalService.parentWidth >= this.globalService.parentHeight;
      }
      let orderId = '';
      if (event.data.type === 'orientation-change') {
        if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
          this.globalService.setResolutionSettings(this.globalService.landscape);
        } else {
          this.globalService.landscape = Math.abs(+event.data.angle) === 90;
          this.globalService.setResolutionSettings(this.globalService.landscape);
        }
        this.globalService.showAside = false;
      }
      if (event.data.type === 'configurator-active') {
        setTimeout(() => {
          if (this.globalService.animationAvailable) {
            this.globalService.isActiveProduct = event.data.configuratorActive;
            this.configuratorService.iframeCallback.next(JSON.stringify({ event: 'animation' }));
          }
        }, 4000);
      }
      if (event.data.type === 'order') {
        orderId = event.data.id;
        this.createOrder(event.data);
      }
      if (event.data.type === 'noId') {
        this.productsService.userData.next(Object.assign(event.data, { id: orderId }, { type: 'save' }));
        window.top.postMessage(
          {
            type: 'display',
            id: orderId,
            height: this.globalService.resolutionSettings.height
          },
          '*'
        );
      }
      if (event.data.key) {
        if (event.data.gfgLanding) {
          this.globalService.gfgLanding = true;
        }
        if (event.data.fullscreenShow) {
          this.productsService.openFull = true;
        }
        this.authService.clientKey = event.data;
        this.api.changeUrl(environment.BACK_END_URL_PLUGIN, 'plugin');
        this.authService.getToken().subscribe((resp: TokenResp) => {
          sessionStorage.setItem('token', resp.token);
          this.setStyleProperties(resp);
          this.setConfiguratorLanguage(resp.language);
          if (event.data.id != null) {
            this.globalService.typeOfPlugin = 'configurator';
            this.productsService.userData.next({ id: event.data.id });
            this.productsService.getId(event.data.id).subscribe(
              (response: ProductCode) => {
                this.initSubCollection(response);
              },
              (error) => {
                window.top.postMessage({ type: 'close' }, '*');
              }
            );
          } else {
            this.globalService.typeOfPlugin = 'full';
            window.top.postMessage(
              {
                type: 'display',
                height: this.globalService.resolutionSettings.height
              },
              '*'
            );
            this.productsService.getProducts();
          }
        });
      }
      if (event.data.escape) {
        this.onCloseFullScreen();
      }
    } else if (!navigator.cookieEnabled && this.cookieMessageShow && this.globalService.typeOfOrigin !== 'gfg') {
      window.top.postMessage(
        {
          type: 'display',
          height: this.globalService.resolutionSettings.height
        },
        '*'
      );
      this.cookieMessageShow = false;
      this.getBrowserLink();
    }
    this.globalService.heightChanged.subscribe((heightValue) => {
      window.top.postMessage({ type: 'display', height: heightValue }, '*');
    });
  }
}
