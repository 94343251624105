import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AreaData } from '../../interfaces/configurator';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  public hideAdditionalInfo = false;
  public hideAdditional;
  public inputAreaParams: AreaData = null;
  public showPrice = false;
  public openedOrderDialog = false;
  public typeOfPlugin: string = null;
  public gfgLanding = false;
  public isIphone = false;
  public typeOfDevice;
  public heightDevice;
  public showAside = false;
  public landscape = false;
  public isActiveProduct = false;
  public parentHeight: number;
  public qtyOfProduct = 1;
  public parentWidth: number;
  public producerName: string;
  public heightChanged = new Subject<string>();
  public siteColor = {
    primary_color: '#f5a623',
    secondary_color: '',
    text_color: '#1e1d31'
  };
  public typeOfOrigin = 'plugin';
  public applyConfigurationQR = false;
  public animationAvailable = false;
  public originUrl = '';
  public slideNavTabs = [
    { title: 'Dimensions', ui: 'Dimensions', active: true },
    { title: 'Materials', ui: 'Materials', active: false },
    { title: 'Additional', ui: 'Additional', active: false },
    { title: 'Palette', ui: 'Palette', active: false }
  ];
  public resolutionSettings = {
    mobileVersion: null,
    orientation: 'portrait',
    showQTYText: true,
    height: '768px'
  };
  public isMobile = false;

  setResolutionSettings(landscape): void {
    this.resolutionSettings.orientation = landscape ? 'landscape' : 'portrait';
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    if (isIOS) {
      this.hideAdditionalInfo = false;
      this.showAside = false;
      const initLandscape = this.parentWidth >= this.parentHeight;
      if (initLandscape && window.innerWidth !== window.innerHeight) {
        this.resolutionSettings = {
          mobileVersion: this.typeOfDevice,
          orientation: 'landscape',
          showQTYText: window.innerWidth > 1024,
          height: this.heightDevice
        };
      } else if (
        window.innerWidth >= window.innerHeight &&
        ((window.innerWidth > 500 && this.typeOfDevice === 'mobile') || (window.innerWidth > 850 && this.typeOfDevice === 'tablet'))
      ) {
        this.landscape = true;
        setTimeout(() => {
          const iosPortraitElement = document.getElementsByClassName('public') as HTMLCollectionOf<HTMLElement>;
          iosPortraitElement[0].style.height = (initLandscape ? this.parentHeight : this.parentWidth) + 'px';
          iosPortraitElement[0].style.width = (initLandscape ? this.parentWidth : this.parentHeight) + 'px';
          window.top.postMessage(
            {
              type: 'rotateIOS',
              height: (initLandscape ? this.parentHeight : this.parentWidth) + 'px',
              width: (initLandscape ? this.parentWidth : this.parentHeight) + 'px'
            },
            '*'
          );
          this.resolutionSettings = {
            mobileVersion: this.typeOfDevice,
            orientation: 'landscape',
            showQTYText: window.innerWidth > 1024,
            height: this.heightDevice
          };
        });
      } else {
        this.landscape = false;
        setTimeout(() => {
          const iosPortraitElement = document.getElementsByClassName('public') as HTMLCollectionOf<HTMLElement>;
          iosPortraitElement[0].style.height = (initLandscape ? this.parentWidth : this.parentHeight) + 'px';
          iosPortraitElement[0].style.width = (initLandscape ? this.parentHeight : this.parentWidth) + 'px';
          window.top.postMessage(
            {
              type: 'rotateIOS',
              height: (initLandscape ? this.parentWidth : this.parentHeight) + 'px',
              width: (initLandscape ? this.parentHeight : this.parentWidth) + 'px'
            },
            '*'
          );
          this.resolutionSettings = {
            mobileVersion: this.typeOfDevice,
            orientation: 'portrait',
            showQTYText: window.innerWidth > 1024,
            height: this.heightDevice
          };
        });
      }
    } else {
      if (landscape) {
        this.resolutionSettings = {
          mobileVersion: this.typeOfDevice,
          orientation: 'landscape',
          showQTYText: window.innerWidth > 1024,
          height: this.heightDevice
        };
      } else {
        this.resolutionSettings = {
          mobileVersion: this.typeOfDevice,
          orientation: 'portrait',
          showQTYText: window.innerWidth > 1024,
          height: this.heightDevice
        };
      }
    }
  }

  // iPhone model checks.
  // getiPhoneModel() {
  //     // Create a canvas element which can be used to retrieve information about the GPU.
  //     let renderer;
  //     let info;
  //     const canvas = document.createElement('canvas');
  //     if (canvas) {
  //         const context = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
  //         if (context) {
  //             info = context.getExtension('WEBGL_debug_renderer_info');
  //             if (info) {
  //                 renderer = context.getParameter(info.UNMASKED_RENDERER_WEBGL);
  //             }
  //         }
  //     }
  //     // iPhone X
  //     if ((window.screen.height / window.screen.width === 812 / 375) && (window.devicePixelRatio === 3)) {
  //         return 'iPhone X';
  //     } else if ((window.screen.height / window.screen.width === 736 / 414) && (window.devicePixelRatio === 3)) {
  //         switch (renderer) {
  //             default:
  //                 return 'iPhone 6';
  //             case 'Apple A8 GPU':
  //                 return 'iPhone 6';
  //             case 'Apple A9 GPU':
  //                 return 'iPhone 6';
  //             case 'Apple A10 GPU':
  //                 return 'iPhone 7';
  //             case 'Apple A11 GPU':
  //                 return 'iPhone 8';
  //         }
  //     } else if ((window.screen.height / window.screen.width === 667 / 375) && (window.devicePixelRatio === 3)) {
  //         switch (renderer) {
  //             default:
  //                 return 'iPhone 6';
  //             case 'Apple A8 GPU':
  //                 return 'iPhone 6';
  //             case 'Apple A9 GPU':
  //                 return 'iPhone 6';
  //             case 'Apple A10 GPU':
  //                 return 'iPhone 7';
  //             case 'Apple A11 GPU':
  //                 return 'iPhone 8';
  //         }
  //         // iPhone 6/6s/7 and 8
  //     } else if ((window.screen.height / window.screen.width === 667 / 375) && (window.devicePixelRatio === 2)) {
  //         switch (renderer) {
  //             default:
  //                 return 'iPhone 6';
  //             case 'Apple A8 GPU':
  //                 return 'iPhone 6';
  //             case 'Apple A9 GPU':
  //                 return 'iPhone 6';
  //             case 'Apple A10 GPU':
  //                 return 'iPhone 7';
  //             case 'Apple A11 GPU':
  //                 return 'iPhone 8';
  //         }
  //     } else if ((window.screen.height / window.screen.width === 1.775) && (window.devicePixelRatio === 2)) {
  //         switch (renderer) {
  //             default:
  //                 return 'iPhone 5';
  //             case 'PowerVR SGX 543':
  //                 return 'iPhone 5';
  //             case 'Apple A7 GPU':
  //                 return 'iPhone 5';
  //             case 'Apple A8 GPU':
  //                 return 'iPhone 6';
  //             case 'Apple A9 GPU':
  //                 return 'iPhone SE';
  //             case 'Apple A10 GPU':
  //                 return 'iPhone 7';
  //             case 'Apple A11 GPU':
  //                 return 'iPhone 8';
  //         }
  //     } else if ((window.screen.height / window.screen.width === 1.5) && (window.devicePixelRatio === 2)) {
  //         switch (renderer) {
  //             default:
  //                 return 'iPhone 4';
  //             case 'PowerVR SGX 535':
  //                 return 'iPhone 4';
  //             case 'PowerVR SGX 543':
  //                 return 'iPhone 4';
  //         }
  //     }
  // }

  detectMobileDevices(): boolean | RegExpMatchArray {
    const iOS = navigator.userAgent.match(/iPhone|iPad|iPod/i);
    const android = navigator.userAgent.match(/Android/i);
    const iPadOS = /Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1;
    return iOS || android || iPadOS;
  }
}
