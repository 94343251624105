import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

export enum Icons {
  dimensions = 'dimensions-icon',
  animation = 'animation-icon',
  rotation = 'rotation-icon',
  qrCode = 'qr-code-icon',
  fullScreen = 'full-screen-icon',
  areaAsideSticker = 'area-aside-sticker-icon',
  areaAsideArrow = 'area-aside-arrow-icon',
  additional = 'additional-icon',
  palette = 'palette-icon',
  materials = 'materials-icon',
  sizes = 'sizes-icon',
  product = 'product-icon',
  eyeOfMaterial = 'eye-of-material-icon',
  activeMaterial = 'active-material-icon',
  qtyArrow = 'qty-arrow-icon',
  success = 'success-icon',
  light = 'light-icon',
  requestDemo = 'request-model-icon',
  infoIcon = 'info-icon',
  fillAllIcon = 'fill-all-icon',
  clearFilterIcon = 'clear-filter-icon',
  clearIcon = 'clear-icon',
  settingsIcon = 'settings-icon',
}

@Injectable({
  providedIn: 'root'
})
export class MatIconService {
  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {}

  public registerIcons(): void {
    this.loadIcons(Object.values(Icons), 'assets/icons');
  }

  private loadIcons(iconKeys: string[], iconUrl: string): void {
    iconKeys.forEach((key) => {
      this.matIconRegistry.addSvgIcon(`gfg-${key}`, this.domSanitizer.bypassSecurityTrustResourceUrl(`${iconUrl}/${key}.svg`));
    });
  }
}
