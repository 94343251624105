import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import * as _ from 'lodash';
import { ApiService } from '../../api/api';
import { CollectionProduct } from '../../interfaces/products/collection-product.interface';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {
  public productsArray = new BehaviorSubject({});
  public userData = new BehaviorSubject({});
  public arrayOfProducts = [];
  public page = 1;
  public subCollection: CollectionProduct = null;
  public openFull = false;
  private url = this.api.URL;
  private productsEndPoint = '/iframe_api/products/search';

  constructor(private http: HttpClient, private api: ApiService) {}

  getProductsData(page) {
    return this.http.get(`${this.url + this.productsEndPoint}/paging/?per_page=20&page=${page}`);
  }

  getPalettesTitles(productId, gfg) {
    if (gfg) {
      return this.http.get(`${this.url}/app/product_details/${productId}/palettes/`);
    } else {
      return this.http.get(`${this.url}/iframe_api/products/${productId}/palettes/`);
    }
  }

  getPalettes(productId, paletteId, gfg) {
    if (gfg) {
      return this.http.get(`${this.url}/app/product_details/${productId}/palettes/${paletteId}/colors/?page_size=500`);
    } else {
      return this.http.get(`${this.url}/iframe_api/products/${productId}/palettes/${paletteId}/colors/?page_size=500`);
    }
  }

  getPalettesNextPages(nextPage) {
    return this.http.get(nextPage);
  }

  searchProduct(query) {
    return this.http.get(`${this.url}/iframe_api/search_everything/?every_name=${query}`);
  }

  getId(code) {
    return this.http.post(`${this.url}/iframe_api/product_code/`, { code });
  }

  subCollections(query): Observable<CollectionProduct> {
    const unitTables = query.unit_tables ? '&unit_tables=true' : '';
    return this.http.get<CollectionProduct>(`${this.url + this.productsEndPoint}/collection/?sub_collection_id=${query.pk + unitTables}`);
  }

  getProducts() {
    this.getProductsData(this.page).subscribe((resp: any) => {
      if (!_.isEmpty(resp.paging_products)) {
        this.arrayOfProducts = [...this.arrayOfProducts].concat(resp.paging_products);
        this.productsArray.next({
          type: 'scroll',
          products: this.arrayOfProducts
        });
      }
    });
    this.page += 1;
  }
}
